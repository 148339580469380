import { useEffect, useState } from 'react';
import HtmlOutput from '../../assets/js/HtmlOutput';
import './faq.css';
import { FaRegStar, FaStar } from "react-icons/fa6";
import { BsPencil, BsTrash3 } from "react-icons/bs";
import ErrorLabel from '../Validation/ErrorLabel';

export default function FaqEdit(props: any) {
    const [questions, setQuestions] = useState<any>({});
    const [questionEdited, setQuestionEdited] = useState<any>({});
    const [confirmFaqDeleteId, setConfirmFaqDeleteId] = useState<string>('');

    useEffect(() => {
        props.details.forEach((item: any) => {
            item['editMode'] = false;
        })
        setQuestions(props.details);
    }, [])

    function generateUniqueID() {
        return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
            (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
        );
    }

    const addNewQuestion = () => {
        let newQuestion = {
            "id": generateUniqueID(),
            "ques": "",
            "ans": "",
            "fav": false,
            "editMode": true,
            "new": true
        }
        setQuestionEdited(newQuestion);
        let allQuestions: any = JSON.parse(JSON.stringify(questions));
        allQuestions.unshift(newQuestion);
        setQuestions(allQuestions)
    }

    const editQuestion = (index: any) => {
        let allQuestions: any = JSON.parse(JSON.stringify(questions));
        allQuestions.forEach((item: any) => {
            if (item.id === index) {
                delete item['error'];
                item.editMode = !item.editMode;
                let currentQuestion = Object.assign({}, item);
                setQuestionEdited(currentQuestion);
            } else {
                if (!item.new) {
                    item.editMode = false;
                    delete item['error'];
                }
            }
        })
        setQuestions(allQuestions);
    }

    const cancelEdit = (id: any) => {
        /* If a new question is being added, we remove that element from the array. If the user is editing and existing question, then just set the editMode to false. */
        let allQuestions: any = JSON.parse(JSON.stringify(questions));
        allQuestions.forEach((item: any) => {
            if (item.id === id) {
                if (item.new) {
                    allQuestions.splice(allQuestions.indexOf(item), 1);
                } else {
                    item.editMode = false;
                }
            }
        })
        setQuestions(allQuestions);
    }

    const saveQuestion = (id: any) => {
        let allQuestions: any = JSON.parse(JSON.stringify(questions));
        if (questionEdited.ques === '' || questionEdited.ans === '') {
            allQuestions.forEach((item: any) => {
                if (item.id === id) {
                    item['error'] = "Please add text for both Question and Answer."
                }
            })
            setQuestions(allQuestions);
        } else {
            allQuestions.forEach((item: any) => {
                if (item.id === id) {
                    item.ans = questionEdited.ans;
                    item.fav = questionEdited.fav;
                    item.ques = questionEdited.ques;
                    item.new = false;
                    item.editMode = false;
                    delete item['error'];
                }
            })
            setQuestions(allQuestions);
            props.updatedQuestions(allQuestions);
        }

    }

    const markQuestionAsFavourite = (id: any) => {
        let allQuestions: any = JSON.parse(JSON.stringify(questions));
        allQuestions.map((item: any) => {
            if (item.id === id) {
                item.fav = !item.fav;
            }
        })
        setQuestions(allQuestions);
        props.updatedQuestions(allQuestions);
    }

    const markQuestionAsFavouriteEditMode = (id: any) => {
        let currentQuestion = Object.assign({}, questionEdited);
        currentQuestion.fav = !currentQuestion.fav;
        setQuestionEdited(currentQuestion);
    }

    const togglePreview = (e: any) => {
        let currentQuestion = Object.assign({}, questionEdited);
        currentQuestion['preview'] = e.target.checked;
        setQuestionEdited(currentQuestion);
    }

    const handleTextInput = (type: string, e: any) => {
        let currentQuestion = Object.assign({}, questionEdited);
        if (type === 'question') {
            currentQuestion.ques = e.target.value;
        } else if (type === 'answer') {
            currentQuestion.ans = e.target.value;
        }
        setQuestionEdited(currentQuestion);
    }

    const confirmDeleteFaq = (id: string) => {
        setConfirmFaqDeleteId(id);
    }

    const deleteFaq = () => {
        /* If a new question is being added, we remove that element from the array. If the user is editing and existing question, then just set the editMode to false. */
        let allQuestions: any = JSON.parse(JSON.stringify(questions));
        allQuestions.forEach((item: any) => {
            if (item.id === confirmFaqDeleteId) {
                allQuestions.splice(allQuestions.indexOf(item), 1);
            }
        })
        setConfirmFaqDeleteId('');
        setQuestions(allQuestions);
        props.updatedQuestions(allQuestions);
    }

    return (
        <>
            <div className='row editFaqSectionHeading'>
                <h1 className='resoureceDivLabel'>
                    Frequently Asked Questions
                </h1>
            </div>
            <div className="addNewQuestionButton">
                <button
                    onClick={() => addNewQuestion()}
                    className='muse-button-primary muse-button-primary--small cursorPointer'
                    id='addNewQuestion'
                >
                    Add new FAQ
                </button>
            </div>
            {questions ? questions.length > 0 &&
                questions.map((dt: any, index: any) => {
                    return (
                        <div className='muse-accordion' key={dt.id}>
                            {
                                dt.editMode ?
                                    /* EDIT MODE */
                                    <div className='muse-accordion' key={dt.id}>
                                        <div className='muse-accordion-title' tabIndex={0} role="button" id={dt?.id}>
                                            <div style={{ "width": "100%" }} className='muse-accordion-section'>
                                                <div className='muse-accordion-title '>
                                                    <div style={{ "width": "100%" }} className='muse-input-wrapper faqTextControlsWrapper'>
                                                        <div style={{ "width": "85%" }}>
                                                            <label className='muse-input-label' htmlFor='firstName'>
                                                                Question
                                                            </label>
                                                            <input type='text' className='muse-input-field' name='question' id={`question_${dt.id}`}
                                                                defaultValue={dt.ques} autoComplete='off' autoFocus aria-required='true' aria-label='Question' onChange={(e) => handleTextInput('question', e)} />

                                                        </div>
                                                        <div className="faqControls" style={{ "marginRight": "15px" }}>
                                                            {
                                                                questionEdited.fav ? <FaStar title="Unmark as Popular" color='#FBAB2C' cursor={'pointer'} onClick={() => markQuestionAsFavouriteEditMode(dt.id)} /> : <FaRegStar title="Mark as Popular" cursor={'pointer'} onClick={() => markQuestionAsFavouriteEditMode(dt.id)} />
                                                            }
                                                        </div>

                                                    </div>

                                                </div>
                                                <div>
                                                    <div className='muse-input-wrapper' style={{ height: '100px', marginBottom: '15px' }}>
                                                        <label className='muse-input-label' htmlFor='answerText'>
                                                            Answer
                                                        </label>
                                                        <textarea className='muse-input-field'
                                                            name='answerText'
                                                            id={`answerText_${dt.id}`}
                                                            defaultValue={dt.ans}
                                                            style={{ resize: 'none', width: '100%' }}
                                                            aria-label='Answer Text'
                                                            aria-describedby='Please enter answer here.'
                                                            onChange={(e) => handleTextInput('answer', e)} />
                                                    </div>

                                                    {
                                                        questionEdited.preview ? <div className='muse-input-wrapper' style={{ height: '115px', marginBottom: '15px' }}>
                                                            <label className='muse-input-label' htmlFor='preview'>
                                                                Preview
                                                            </label>

                                                            <div style={{ height: '100px', overflow: 'auto', padding: '0 10px' }}>
                                                                <HtmlOutput htmlContent={questionEdited.ans} />
                                                            </div>
                                                        </div> : ''
                                                    }


                                                    <div className="editQuestionButtonHolder">

                                                        <div>
                                                            <label className="muse-checkbox">
                                                                <input type="checkbox" onChange={(e) => togglePreview(e)} />
                                                                <div className="muse-custom-checkmark">

                                                                </div>
                                                                <div className="muse-checkbox-text">
                                                                    <div className="muse-checkbox-label">
                                                                        Show Preview
                                                                    </div>
                                                                </div>
                                                            </label>

                                                        </div>
                                                        <div>

                                                            <button onClick={() => cancelEdit(dt.id)}
                                                                className='muse-button-secondary muse-button-secondary--small cursorPointer'
                                                                id='cancelButton'
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button onClick={() => saveQuestion(dt.id)}
                                                                className='muse-button-primary muse-button-primary--small cursorPointer'
                                                                id='saveButton'
                                                            >
                                                                Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {
                                                        dt.error && dt.error !== '' ?
                                                            <ErrorLabel
                                                                class='muse-input-caption editFaqError'
                                                                style={{ color: 'red' }}
                                                                error={dt.error}
                                                            /> : ''
                                                    }

                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    :

                                    /* REGULAR MODE */
                                    <div className='muse-accordion' key={dt.id}>
                                        <div
                                            className='muse-accordion-title'
                                            tabIndex={0}
                                            role="button"
                                            id={dt?.id}
                                        >
                                            <div style={{ "width": "100%" }} className='muse-accordion-section'>
                                                <div className='muse-accordion-title '>
                                                    <div style={{ "width": "85%" }}>
                                                        <div className='muse-text-style-title-reduced' onClick={() => editQuestion(dt.id)}>
                                                            {dt.ques}
                                                        </div>
                                                    </div>
                                                    <div className="faqControls">
                                                        {
                                                            dt.fav ? <FaStar title="Unmark as Popular" color='#FBAB2C' cursor={'pointer'} onClick={() => markQuestionAsFavourite(dt.id)} /> : <FaRegStar title="Mark as Popular" cursor={'pointer'} onClick={() => markQuestionAsFavourite(dt.id)} />
                                                        }
                                                        {
                                                            !dt.new ?
                                                                <>
                                                                    <BsPencil className='icons' title="Edit Question" color='#606162' cursor={'pointer'} onClick={() => editQuestion(dt.id)} />
                                                                    <BsTrash3 className='icons' title="Delete Question" color='#606162' cursor={'pointer'} onClick={() => confirmDeleteFaq(dt.id)} />
                                                                </>
                                                                : ''
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                            <div className='muse-divider'>
                                <div className='muse-divider-text'></div>
                            </div>
                        </div>
                    )
                })
                : ''
            }

            {
                confirmFaqDeleteId !== '' ? <div className="modal-overlay" aria-modal="true">
                    <div className="modal-content" style={{ maxWidth: '500px' }}>
                        <header className="muse-modal-dialog-header" style={{ textAlign: 'center' }}>
                            <h1 className="muse-headline-heading muse-mt-0">Are you sure you want delete this FAQ?</h1>
                        </header>
                        <article className="muse-modal-dialog-content-slot muse-mt-40" style={{ textAlign: 'center' }}>
                            <strong>This action cannot be undone!</strong>
                        </article>
                        <div className="faqEditModalFooterButtons">
                            <button
                                onClick={() => confirmDeleteFaq('')}
                                className='muse-button-secondary muse-button-primary--small cursorPointer'
                                id='faqConfirmDeleteNo'
                            >
                                No
                            </button>
                            <button
                                onClick={() => deleteFaq()}
                                className='muse-button-primary muse-button-primary--small cursorPointer'
                                id='faqConfirmDeleteYes'
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </div> : ''
            }
        </>
    );
}