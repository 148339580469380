import React, { useEffect, useState } from 'react';
import './faq.css';
import { Link } from 'react-router-dom';
import { ReactComponent as EnquiryLogo } from '../../assets/svg/enquiryLogo.svg';
import { ReactComponent as EnquiryLogoMobile } from '../../assets/svg/enquiryLogoMobile.svg';
import { ReactComponent as LogoLady } from '../../assets/svg/ladySvg.svg';
import { ReactComponent as EnquiryLogoLoggedIn } from '../../assets/svg/enquiryLoggedIn.svg';
import { ReactComponent as EnquiryLogoLoggedInNew } from '../../assets/svg/newenquiryLoggedIn.svg';
import Accordion from '../Accordion/Accordion';
import { getPopularFaqs } from '../../services/commonService';
import { apiUrl } from '../../services/commonService';

export default function FaqSection() {
  const [checkLogin, setCheckLogin] = useState<boolean>(false);
  const [popfaqData, setPopFaqData] = useState<any>([]);

  //get Popular Faq Data
  useEffect(() => {
    const getPopularData = async () => {
      try {
        const popularFaqData = await getPopularFaqs();
        if (popularFaqData.data.msg) {
          console.error('Failed to fetch faq data:', popularFaqData.data.msg);
        } else {
          setPopFaqData(popularFaqData.data.FAQ);
        }
      } catch (error) {
        console.error('Failed to fetch faq data:', error);
      }
    };
    getPopularData();
  }, []);

  const callLoginUrl = (event: any) => {
    event.preventDefault();
    window.location.href = `${apiUrl()}okta/login`;
  };
  useEffect(() => {
    setCheckLogin(
      sessionStorage.getItem('accessToken') === null ||
        sessionStorage.getItem('accessToken') === ''
        ? false
        : true
    );
  }, []);

  const submitEnquiryLog = async (event: any) => {
    event.preventDefault();
  };

  return (
    <>
      <div className='faqSectionDivs'>
        <div className='mT64 faqSectionDivsChild'>
          <div
            className='muse-card'
            id='faqinnserSection'
          >
            <div>
              <h2 className='faqLabel'>FAQ</h2>
              <h3 className='faqSubLabel muse-mt-10'>Most Popular Questions</h3>
            </div>
            <div id='accordianDiv' className='muse-mt-80 scrollable-div mainPageAccordionDivMaxHeight'>
              <Accordion details={popfaqData} />
            </div>
          </div>
          <div
          className={`muse-mt-40 muse-mb-40 ${
            checkLogin ? 'tabViewFaqBtnLoggedIn' : 'tabViewFaqBtn'
          }`}
          style={{"width":"fit-content"}}
        >
          <Link to='/faq' className='muse-button-secondary faqButton'>
            View all FAQs
          </Link>
        </div>
        </div>

        {/* for the non Logged In user this section will be visible */}
        {checkLogin ? (
          <div id='loggedin' className='mT64 faqSectionDivsChild2'>
            <div className='muse-card'>
              <div>
                <div
                  id='LoginSubmitEnquiry'
                  className='LoginSubmitEnquiry'
                  aria-label='LoginSubmitEnquiry'
                >
                  <div className='foundLabelLoggedIn' style={{ width: '100%' }}>
                    <p className='mt13'>Haven’t found what you're looking for?</p>
                    <div className='muse-mt-60'>
                      <Link
                        to='/inquiry'
                        className='muse-button-primary enquirySubmit'
                      >
                        Submit Inquiry
                      </Link>
                    </div>
                    {/* <EnquiryLogoLoggedIn className='mt69'></EnquiryLogoLoggedIn> */}
                    <div className="newlogo" style={{"display":"flex", "justifyContent":"center"}}>
                      <EnquiryLogoLoggedInNew className='mt50'></EnquiryLogoLoggedInNew>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div id='nonLoggedin' className='mT64 faqSectionDivsChild2'>
            <div className='muse-card'>
              <div>
                <div
                  id='nonLoginSubmitEnquiry'
                  className='nonLoginSubmitEnquiry'
                >
                  <div id='iconDiv'>
                    <EnquiryLogo></EnquiryLogo>
                  </div>
                  <div className='foundLabel'>
                    <p className='mt13'>Haven’t found what you're looking for?</p>
                    <div className='muse-mt-60'>
                      <Link
                        to='/inquiry'
                        className='muse-button-primary enquirySubmit'
                      >
                        Submit Inquiry
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  id='nonLoginSubmitEnquiryMobile'
                  className='nonLoginSubmitEnquiryMobile'
                >
                  <div id='iconDivMobile' style={{ width: '50%' }}>
                    <EnquiryLogoMobile />
                  </div>
                  <div className='foundLabelMobile' style={{ width: '50%' }}>
                    <p className='mt13'>Haven’t found what you're looking for?</p>
                    <div className='muse-mt-60'>
                      <Link
                        to='/inquiry'
                        className='muse-button-primary enquirySubmit'
                        onClick={submitEnquiryLog}
                      >
                        Submit Inquiry
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  id='signInDiv'
                  className='signInDiv'
                >
                  <label
                    id='getHelpLabel'
                    className='getHelpLabel'
                  >
                    Get help faster and easier
                  </label>
                  <div className='row signInInnerDiv mt6 displayFelx'>
                    <div>
                      <button
                        className='muse-button-primary mt26'
                        onClick={callLoginUrl}
                        style={{cursor: 'pointer'}}
                      >
                        Sign in
                      </button>
                      {/* <label className='marLR18'>New user?</label>
                      <a className='marL18 marB20' href='#'>
                        Create an account
                      </a> */}
                    </div>
                    <div>
                      <LogoLady style={{ float: 'right' }}></LogoLady>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        
      </div>
    </>
  );
}
