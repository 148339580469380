import { useEffect, memo } from 'react';
declare const window: any;

const RecaptchaV2 = memo(function RecaptchaV2(props: any) {
    const captchaV2Key: string = (
        window.location.hostname.includes('localhost') ||
        window.location.hostname.includes('stg') ||
        window.location.hostname.includes('dev')) ?
        process.env.REACT_APP_PUBLIC_CAPTCHA_KEY_V2_NPRD || '' :
        process.env.REACT_APP_PUBLIC_CAPTCHA_KEY_V2_PROD || ''

    const grecaptcha = window.grecaptcha

    useEffect(() => {
        setTimeout(() => {
            const script = document.createElement('script');
            script.src = "https://www.google.com/recaptcha/enterprise.js?render=explicit";
            script.async = true;
            script.defer = true;
            script.onload = () => {
                if (grecaptcha && document.getElementById('captchaHolder') != null) {
                    setTimeout(() => {
                        let widgetID = grecaptcha.enterprise.render(document.getElementById('captchaHolder'), {
                            'sitekey': captchaV2Key,
                            'callback': verifyCallback,
                            'error-callback': errorCallback	
                        });
                    }, 100);
                }
            }
            document.body.appendChild(script);
        }, 0);
    }, []);

    function errorCallback() {
        //console.log("Something is wrong with captcha");
        if (document.getElementById('captchaHolder') != null) {
            grecaptcha.enterprise.reset();
        }
    }

    function verifyCallback() {
        let response: string = grecaptcha.enterprise.getResponse()
        props.captchaCreated(response);
    }

    return (
        <div id="captchaHolder">

        </div>
    )
})

export default RecaptchaV2;