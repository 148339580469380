import React, { useEffect, useRef, useState } from 'react';
import './Modal.css';
import OtpInput from 'react-otp-input';
import { BsCheck2Circle } from "react-icons/bs";
import { verify_email, authorize_email } from '../../services/commonService';

interface ModalBoxProps {
  ModalOpen: any;
  From?: any;
  data?: any;
}

const ModalBox: React.FC<ModalBoxProps> = ({ ModalOpen, From, data }) => {
  const [code, setCode] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [time, setTime] = useState(120);
  const timerIdRef = useRef<NodeJS.Timeout | null>(null); // Store the timerId using useRef
  const [pwMessageState, setpwMessageState] = useState(false);

  const handleClose = () => {
    ModalOpen(false)
  }

  const resendOTP = async () => {
    try {
      stopTimer()
      setErrorMessage("");
      setCode("");
      const params = { email_address: data };
      let a = await authorize_email(params);
      if (a?.status === 200) {
        setTime(120);
        const cleanupTimer = startTimer();
        return cleanupTimer;
      }
    } catch (error) {
      console.error('error ', error);
    }
  };

  const handleVerifyEmail = (val: any) => {
    ModalOpen({ open: val.open, type: val.type });
  }

  const handleChange = (code: any) => setCode(code);

  const verifyEmailAddress = async () => {
    try {
      if (code.length !== 6) return setErrorMessage("Please enter 6 digit OTP");

      const params = { otp: code, email_address: data };
      const response = await verify_email(params);
      if (response?.status === 200) {
        setIsVerified(true);
        setErrorMessage("");
      } else {
        setIsVerified(false);
        setCode("");
        setErrorMessage("OTP expired or invalid. Please re-enter again.");
      }
    } catch (error) {
      setIsVerified(false);
      setCode("");
      setErrorMessage("OTP expired or invalid. Please re-enter again.");
    }
  };

  useEffect(() => {
    // Start the timer and add no-scroll class to the body
    startTimer();
    document.body.classList.add('no-scroll');
    disableButton('btn_close');
    return () => {
      document.body.classList.remove('no-scroll');
      stopTimer(); // Clear the timer
    };
  }, []);

  const startTimer = () => {
    timerIdRef.current = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          setErrorMessage("OTP time expired. Please retry again.");
          if (timerIdRef.current !== null) {
            clearInterval(timerIdRef.current);
          }
          return 0;
        }
      });
    }, 1000); // Update every second

    // Cleanup interval on component unmount
    return () => stopTimer();
  };

  const stopTimer = () => {
    if (timerIdRef.current) {
      clearInterval(timerIdRef.current); // Clear the interval on unmount
    }
  };

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  const disableButton = (id: string) => {
    let element = document.getElementById(id) as HTMLInputElement;
    if (element) {
      element.classList.add('muse-disabled-button');
      element.disabled = true;
    }
  };

  const enableButton = (id: string) => {
    let element = document.getElementById(id) as HTMLInputElement;
    if (element) {
      element.classList.remove('muse-disabled-button');
      element.disabled = false;
    }
  };

  const checkAgree = (value: any) => {
    if (value.target.checked) {
      enableButton('btn_close');
    }
  }

  const renderModal = (From: string) => {
    switch (From) {
      case 'pw-notification':
        return (
          <div className="modal-overlay" aria-modal="true">
            <div className="modal-content modal-content-pw-info">
              <div>
                <h4>Have you tried these steps?</h4>
                <p className="margin-top-large">If you are having trouble logging into your <a href="http://cisco.com/" title='Visit Cisco.com' target="_blank" rel="noreferrer">Cisco.com</a> account or are looking for help resetting your password, please
                  try following these steps:</p>
                <ol className='margin-bottom'>
                  <li>Please try to clear your browser cache and cookies. Alternatively, you may try to login in an Incognito window
                    or another supported browser (<a href="http://www.cisco.com/web/help/browser.html" title='Visit Browser and Fonts' target="_blank" rel="noreferrer">Browser & Font</a> )</li>
                  <li>In case it still does not work, please try to log in using a different device (PC, phone, etc.) and see if it
                    works.</li>
                  <li>To reset your password: On the <a href="http://cisco.com/" title='Visit Cisco.com' target="_blank" rel="noreferrer">Cisco.com</a> Account Login page, click on “Need help signing in” then click on
                    “Forgot Password” and follow the steps on the screen. You may also follow our Login and Account Help guide: <a href="https://www.cisco.com/c/en/us/about/help/login-account-help.html" title="Open Login and Account Help" target="_blank" rel="noreferrer">Login
                    and Account Help</a></li>
                </ol>
                
                <p>Cisco Global Customer Experience Centers</p>

                <div className="muse-form-field margin-top-large">
                  <label className="muse-radio radioButtonLabel">
                    <input type="radio" onChange={(e) => checkAgree(e)} />
                    <div className="muse-radio-text">
                      <div className="muse-radio-label ">
                       I tried the above and still need help
                      </div>
                    </div>
                  </label>
                </div>

                <div style={{ textAlign: 'right', justifyContent: 'flex-end' }}>
                  <button
                    onClick={handleClose}
                    className='muse-button-primary muse-mt-150 cursorPointer muse-disabled-button margin-top-large'
                    id='btn_close'
                  >
                    Close
                  </button>
                </div>

              </div>
            </div>
          </div>
        )
      case 'footer':
        return (
          <div className="modal-overlay" aria-modal="true">
            <div className="modal-content">
              <div tabIndex={0} style={{ display: 'flex' }}>
                <img style={{ width: '67px', height: '35px' }}
                  src="https://pdx1.qualtrics.com/WRQualtricsSiteIntercept/Graphic.php?IM=IM_9ufPO8lmTRbqSH4"
                  alt="Cisco Logo" />
                <button onClick={handleClose} className="modal-close-button" style={{ marginLeft: 'auto' }}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='19'
                    height='19'
                    fill='none'
                  >
                    <path
                      fill='currentColor'
                      d='m17.5.793-8 8-8-8-.707.707 8 8-8 8 .707.707 8-8 8 8 .707-.707-8-8 8-8L17.5.793Z'
                    ></path>
                  </svg>
                </button>
              </div>
              <iframe src={"https://ciscocx.qualtrics.com/jfe/form/SV_bwrmeoKrBHYxOyW?Ref=%2F"} width="100%" height="500px" title="Feedback Form" />
            </div>
          </div>
        )
      case 'verifyEmail':
        return (
          isVerified ? (
            <div className="modal-overlay" aria-modal="true">
              <div className="modal-content modal-width">
                <div tabIndex={0} style={{ display: 'flex' }}>
                  <button onClick={() => handleVerifyEmail({ open: false, type: 'verified' })} className="modal-close-button" style={{ marginLeft: 'auto' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="none"><path fill="currentColor" d="m17.5.793-8 8-8-8-.707.707 8 8-8 8 .707.707 8-8 8 8 .707-.707-8-8 8-8L17.5.793Z"></path></svg>
                  </button>
                </div>
                <header className="muse-modal-dialog-header" style={{ textAlign: 'center' }}>
                  <h1 className="muse-headline-heading muse-mt-0">Verified</h1>
                </header>
                <article className="muse-modal-dialog-content-slot muse-mt-40" style={{ textAlign: 'center' }}>
                  You have successfully verified your account
                  <div>
                    <BsCheck2Circle style={{ width: "50px", height: "50px" }} />
                  </div>
                </article>
              </div>
            </div>
          ) : (
            <div className="modal-overlay" aria-modal="true">
              <div className="modal-content">
                <div tabIndex={0} style={{ display: 'flex' }}>
                  <button onClick={() => handleVerifyEmail({ open: false, type: 'close' })} className="modal-close-button" style={{ marginLeft: 'auto' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="none"><path fill="currentColor" d="m17.5.793-8 8-8-8-.707.707 8 8-8 8 .707.707 8-8 8 8 .707-.707-8-8 8-8L17.5.793Z"></path></svg>
                  </button>
                </div>
                <header className="muse-modal-dialog-header" style={{ textAlign: 'center' }}>
                  <h1 className="muse-headline-heading muse-mt-0">Verify Your Account</h1>
                </header>
                <article className="muse-modal-dialog-content-slot muse-mt-40" style={{ textAlign: 'center' }}>
                  <p className="muse-text-style-body muse-mt-40">
                    We emailed you the six digit code to {data}
                    <div>Enter the code below to confirm your email address</div>
                  </p>

                  <div className='muse-modal-dialog-content-slot muse-mt-100' style={{ textAlign: 'center' }}>
                    <div className='muse-text-style-body-reduced'>
                      <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', backgroundColor: '#e5ecff', padding: '20px 0' }}>
                        <OtpInput
                          inputStyle={{
                            padding: '0',
                            width: "2em",
                            height: "2em",
                            margin: "20px 1rem",
                            fontSize: "2em",
                            borderRadius: 4,
                            border: "1px solid #ccc"
                          }}
                          onChange={handleChange}
                          numInputs={6}
                          value={code}
                          renderInput={(props) => <input {...props} />}
                        />
                      </div>

                      {errorMessage && <div className='muse-mt-20' style={{ color: 'red' }}>{errorMessage}</div>}

                      {time > 0 &&
                        <div className="muse-mt-20">
                          Time remaining: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                        </div>
                      }

                      <div style={{ margin: '10px' }}><button onClick={verifyEmailAddress} className='muse-button-primary cursor-pointer' tabIndex={0} style={{ width: '170px', height: '45px' }}>
                        Verify
                      </button></div>
                      Didn't receive a code! <b onClick={resendOTP} className='cursor-pointer'>Resend</b>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          )
        )
      default:
        return <div className='muse-modal--partial modelStyle'>
          <div className='muse-modal-dialog'>
            <button onClick={handleClose} className='muse-modal-dialog-close' style={{ "background": "#fff" }} tabIndex={0}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='19'
                height='19'
                fill='none'
              >
                <path
                  fill='currentColor'
                  d='m17.5.793-8 8-8-8-.707.707 8 8-8 8 .707.707 8-8 8 8 .707-.707-8-8 8-8L17.5.793Z'
                ></path>
              </svg>
            </button>
            <div className='muse-modal-dialog-header'>
              <h1 className='muse-headline-heading muse-mt-0'>
                Submitted Successfully
              </h1>
              <p className='muse-text-style-body muse-mt-40'>
                Thank you for submitting the inquiry.
              </p>
            </div>
            <div className='muse-modal-dialog-content-slot muse-mt-100'>
              <p className='muse-text-style-body-reduced'>
                We will get back to you soon!
              </p>
            </div>
            <div className='muse-modal-dialog-footer muse-mt-100 '>
              <button onClick={handleClose} className='muse-button-primary' tabIndex={0}>
                Close
              </button>
            </div>
          </div>
          <div className='muse-modal-scrim'></div>
        </div>
    }
  }

  return (
    <div
      data-testid='myModal'
      id='myModal'
      className={`modal ${ModalOpen ? 'openModal' : ''}`}
    >
      {renderModal(From)}
    </div>
  );
};

export default ModalBox;
