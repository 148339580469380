//Validate Alphanumeric
export const validateAlphabet = (alpha, allowComma = false) => {
  var allowedChars = /^[A-Za-z ]+$/;
  if (allowComma) {
    allowedChars = /^[A-Za-z,]+$/;
  }

  if (alpha.match(allowedChars)) {
    return true;
  }

  return false;
};

// validate Email
export const validateEmail = (e) => {
  var filter =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/;

  return String(e).search(filter) !== -1;
};

//validate comma separated emails
export const validateCommaSeparatedEmails = (e) => {
  var filter = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\s*,\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/;
  
  return filter.test(e);
};

//Convert first letter
export const ucFirst = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// validate URL
export const validateURL = (e) => {
  var filter =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/

  return String(e).search(filter) !== -1;
};
